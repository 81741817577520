import { FC } from "react";
import { Breakdown } from "../models/Quote";

const QuoteDisplayBreakdownRow: FC<{
  breakdown: Breakdown;
  symbol: string;
  index: number;
}> = ({ breakdown, symbol, index }) => {
  return (
    <tr key={index}>
      <td colSpan={2}>
        {breakdown.Days} night{((breakdown.Days > 1) ? "s" : "")} at {symbol}{Math.floor(breakdown.WeeklyPrice)}{" per week (" + symbol}{Math.floor((breakdown.WeeklyPrice / 7) * breakdown.Days)}{")."}
      </td>
    </tr>
  );
};

export default QuoteDisplayBreakdownRow;
