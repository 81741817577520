import { FC } from "react";
import Quote from "../models/Quote";
import moment from "moment";
import QuoteDisplayBreakdownRow from "./QuoteDisplayBreakdownRow";
import QuoteDisplayExtraRow from "./QuoteDisplayExtraRow";

const QuoteDisplay: FC<{ quote: Quote, currency: string }> = ({ quote }) => {
  window.getSelection()?.removeAllRanges();
  const copyQuoteToClipboard = async () => {
    var quoteContent = document.getElementById('quote');
    if(quoteContent !== null) {
      const blobInput = new Blob([quoteContent.innerHTML], {type: 'text/html'});
      const clipboardItemInput = new ClipboardItem({'text/html' : blobInput});
      navigator.clipboard.write([clipboardItemInput]);
    }
  }
  
  return (
    <div className="row card text-bg-dark w-75 mt-3">
      <div className="card-header">
        Thanks for your interest, here's your quote:
      </div>
      <div id="quote" className="card-body">
        <table>
          <tbody>
            <tr>
              <td>
                <b>Arrival Date:</b>
              </td>
              <td>{moment(quote.FromDate).format("dddd MMMM Do YYYY")}</td>
            </tr>
            <tr>
              <td>
                <b>Departure Date:</b>
              </td>
              <td>{moment(quote.ToDate).format("dddd MMMM Do YYYY")}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Price Breakdown:</b>
              </td>
            </tr>
            {quote.Breakdown.map((breakdown, index) => {
              return (
                <QuoteDisplayBreakdownRow
                  breakdown={breakdown}
                  symbol={quote.BasePrice.Currency.Symbol}
                  index={index}
                  key={index}
                />
              );
            })}
            {quote.DiscountRate > 0 && (
              <tr>
                <td colSpan={2}>
                  Less {quote.DiscountRate}% {quote.DiscountType}.
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={2}>
                <b>Base Price: </b>
                {quote.BasePrice.Currency.Symbol}
                {Math.floor(quote.BasePrice.Amount)}
              </td>
            </tr>
            {quote.Extras.length > 0 && (
              <tr>
                <td colSpan={2}>
                  <b>Additional Costs:</b>
                </td>
              </tr>
            )}
            {quote.Extras.map((extra, index) => {
              return (
                <QuoteDisplayExtraRow
                  extra={extra}
                  index={index}
                  key={index}
                />
              );
            })}
            <tr>
              <td colSpan={2}>
                <h4>
                  Booking Total:
                  {quote.BasePrice.Currency.Symbol}
                  {Math.floor(quote.TotalPrice.Amount)}
                </h4>
              </td>
            </tr>
            {quote.BalanceDueDate <= new Date() ? (
              <tr>
                <td>
                  <b>Total Payable Today: </b>
                </td>
                <td>
                  {quote.TotalPrice.Currency.Symbol}$
                  {Math.floor(quote.TotalPrice.Amount)}
                </td>
              </tr>
            ) : (
              <tr>
                <td>
                  <b>Deposit Due Today:</b>
                </td>
                <td>
                  {quote.TotalPrice.Currency.Symbol}
                  {Math.floor(quote.DepositPrice.Amount)}
                </td>
              </tr>
            )}
            <tr>
              <td>
                <b>Balance Due:</b>
              </td>
              <td>
                {quote.TotalPrice.Currency.Symbol}
                {Math.floor(
                  quote.TotalPrice.Amount - quote.DepositPrice.Amount
                )}
              </td>
            </tr>
            <tr>
              <td>
                <b>Balance Due Date:</b>
              </td>
              <td>
                {moment(quote.BalanceDueDate).format("dddd MMMM Do YYYY")}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Refundable Charges:</td>
            </tr>
            <tr>
              <td colSpan={2}>
                {quote.TotalPrice.Currency.Symbol}
                {Math.floor(
                  quote.Extras.reduce((x, y) => {
                    if (y.Refundable) {
                      return x + y.BasePrice.Amount;
                    }
                    return x;
                  }, 0)
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button onClick={copyQuoteToClipboard} className="btn btn-primary">Copy To Clipboard</button>
    </div>
  );
};

export { QuoteDisplay };
