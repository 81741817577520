import { FC, useState, useEffect } from "react";
import { QuoteForm } from "./components/QuoteForm";
import { QuoteDisplay } from "./components/QuoteDisplay";
import { IQuoteRequest } from "./models/IQuoteRequest";
import "./App.css";
import ModalSpinner from "./components/ModalSpinner";
import Quote from "./models/Quote";
import moment from "moment";
import axios from "axios";

let _displayQuote: boolean = true;

const App: FC = () => {
  const defaultReq = {
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().add(14, "days").format("YYYY-MM-DD"),
    numberOfNights: 14,
    currencyCode: "GBP",
    extras: [],
  };
  const [quote, setQuote] = useState<Quote | null>(null);
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState<IQuoteRequest>(defaultReq);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (hasChanged) {
      callQuoteCalculator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, hasChanged]);

  const callQuoteCalculator = async () => {
    try {
      setLoading(true);
      let response = await axios.post(
        "https://dillonwayfunctions.azurewebsites.net/api/quotecalculator",
        request,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let quote: Quote = response.data;
      _displayQuote = true;
      setLoading(false);
      setQuote(quote);
    } catch (error: any) {
      console.log(error);
      setLoading(false);

      throw error;
    }
  };

  return (
    <div className="App container">
      {loading ? <ModalSpinner /> : ""}
      <QuoteForm
        setRequest={(value: IQuoteRequest) => {
          setHasChanged(true);
          setRequest(value);
        }}
        request={request}
      />
      {_displayQuote && quote ? <QuoteDisplay quote={quote} currency={request.currencyCode} /> : null}
    </div>
  );
};

export default App;
