import { FC, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

const ModalSpinner: FC = () => {
  const messages: string[] = [
    "Getting that quote for you...",
    "Hang on a sec...",
    "I'm on it...",
    "I'm all over it...",
    "Working on it...",
    "Let me just go and grab that...",
    "Just getting that for you...",
    "On it like a car bonnet...",
    "All over it like a bad rash...",
    "Your wish is my command...",
  ];

  return (
    <>
      <Modal show={true}>
        <Modal.Header>
          <Modal.Title>
            {messages[Math.floor(Math.random() * messages.length)]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Spinner />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalSpinner;
