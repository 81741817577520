import { FC } from "react";
import { Extra } from "../models/Quote";

const QuoteDisplayExtraRow: FC<{
  extra: Extra;
  index: number;
}> = ({ extra, index }) => {
  return (
    <tr key={index}>
      <td colSpan={2}>
        {extra.Description}: {extra.TotalPrice.Currency.Symbol}{Math.floor(extra.BasePrice.Amount)}
        {extra.NightlyCharge ? ` per week (${extra.TotalPrice.Currency.Symbol}${Math.floor(extra.TotalPrice.Amount)})` : ""}{". "}
        {extra.Refundable ? "(Refundable)" : ""}
      </td>
    </tr>
  );
};

export default QuoteDisplayExtraRow;
