import { FC } from "react";
import moment from "moment";
import { IQuoteRequest } from "../models/IQuoteRequest";

const QuoteForm: FC<{
  setRequest: (value: IQuoteRequest) => void;
  request: IQuoteRequest;
}> = ({ setRequest, request }) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { id, name, value, checked } = e.currentTarget;

    const newRequest: IQuoteRequest = { ...request };
    if (id === "fromDate" || id === "toDate") {
      newRequest[id] = value;

      if (moment(newRequest.toDate) <= moment(newRequest.fromDate)) {
        newRequest.toDate = moment(newRequest.fromDate)
          .add(14, "days")
          .format("YYYY-MM-DD");
      }
    }
    if (id === "poolHeating") {
      newRequest.extras = checked ? ["PoolHeating"] : [];
    }
    if(name === "currency") {
      newRequest.currencyCode = value;
    }
    newRequest.numberOfNights = moment(newRequest.toDate).diff(
      moment(newRequest.fromDate),
      "days"
    );

    setRequest(newRequest);
  };

  return (
    <div className="row card text-bg-info w-75">
      <div>
        <div className="form-group col-lg-4">
          <label 
            className="form-label text-start"
            htmlFor="fromDate">Start Date</label>
          <input
            type="date"
            className="form-control form-control-sm date-picker"
            id="fromDate"
            title="Select Your Start Date"
            value={request.fromDate}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-lg-4">
          <label htmlFor="toDate">End Date</label>
          <input
            type="date"
            className="form-control form-control-sm date-picker"
            id="toDate"
            title="Select Your End Date"
            value={request.toDate}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-lg-4">
          <label htmlFor="Nights">Number of Nights</label>
          <input
            type="number"
            className="form-control form-control-sm text-box"
            id="numberOfNights"
            value={request.numberOfNights}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div
          className="form-group alert alert-info col-lg-4"
          style={{ display: "none" }}
          id="errorMessage"
        ></div>
        <div className="form-check col-lg-2">
          <input
            className="form-check-input radio-button"
            type="radio"
            name="currency"
            id="currencygbp"
            value="GBP"
            onClick={handleChange}
            defaultChecked={true}
          />
           <label className="form-check-label" htmlFor="currencygbp">
            {" "}
            £ GBP{" "}
          </label>
        </div>
        <div className="form-check col-lg-2">
          <input
            className="form-check-input"
            type="radio"
            name="currency"
            id="currencyusd"
            value="USD"
            onClick={handleChange}
          />
           <label className="form-check-label" htmlFor="currencyusd">
            {" "}
            $ USD{" "}
          </label>
        </div>
      </div>
      <div className="row">
        <div className="form-check col-lg-4">
          <label htmlFor="poolHeating" className="form-check-label">
            Include Pool Heating
          </label>
          <input
            type="checkbox"
            name="Extras"
            className="form-check-input check-box"
            id="poolHeating"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};
export { QuoteForm };
